@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

html, body{
    margin:0px;
    padding: 0px;
    overflow-x: hidden;
    background-color: #f5eded;
    font-family: 'Montserrat', sans-serif;
    color:rgba(0, 0, 0, 0.336);
  }
  .profile-p{
    max-width: 500px;
    margin: auto;
    font-family: Arial, Helvetica, sans-serif;
  }
  .menuDiv{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10;
  }
  /*header css*/
.main{
  background-color: hsl(0, 25%, 98%);
  height: 68px;
}
  #mainimg{
    width:auto;
    height: 750px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #ffffff;
    background-image: url("images/background1.jpg");
  }
  .active{
    background-color: #9b047a;
    color:#000000;
    box-shadow: 5px 10px 30px rgba(250, 160, 235, 0.411);
    transition: all ease 0.2s;
  }
  .hey{
    font-family: calibri;
  color:rgba(0, 0, 0, 0.336);
  font-weight: 500;
  font-size: 0.9rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.336);;
  }
  .name{
  font-family: 'Montserrat';
  width: 500px;
  position: absolute;
    left: 50%;
    text-align: center;
  top: 50%;
  transform: translate(-50%,-50%);
  }
  .name span{
    color: #9b047a;
    border-bottom: 2px solid #9b047a;
  }
  .name .details{
    color: #9b047a;
    font-size: 20px;
  }
  .s-box .details{
    font-size: 20px;
    margin-left: 10px;
  }
  .name h1{
  font-family:'Montserrat';
  font-size: 46px;
  margin:0px;
  letter-spacing: 3px;
  color:#9b047a;
  }
  .header-btns{
    display: flex;
    margin-top: 40px;
    margin-left: 6.8em;
  }
  .cv-btn{
  width:110px;
  height: 40px;
  display: flex;
  justify-content: center;
    align-items: center;
    background-color: #9b047a;
    box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.137);
    border-radius: 10px;
  color:#000266;
  }
  .cv-btn1{
    font-size: 14px;
  width:140px;
  height: 40px;
  display: flex;
  justify-content: center;
    align-items: center;
    background: transparent;
    border: 1px solid #ffffff;
    color:#ffffff;
    border-radius: 10px;
    margin-left: 20px;
  }
  .cv-btn:hover{
  background-color: #ffffff;
    transition: all ease 0.5s;
    color: #000266;
  }
  .cv-btn1:hover{
  background-color: #ffffff;
    transition: all ease 0.5s;
    color: #000266;
  }
  .f-heading h1{
    color:#FFFFFF;
    font-size: 2.5rem;
    margin: 0px;
    padding: 0px;
  
  }
  .f-heading p{
    color:rgba(247, 247, 247, 0.678);
    font-size: 1rem;
    margin-top: 10px;
    padding: 0px;
  }
  .f-heading{
    text-align: center;
    margin-top: 60px;
    margin-bottom: -120px;
  }

  #services{
    margin-top: 20px;
    width:100%;
    height:80vh;
    background-color:#dad2d2;
    box-sizing: border-box;
    font-family: calibri;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px ;
  }
  #about{
    color:rgba(10, 10, 10, 0.911);
    margin-top: 20px;
    width:100%;
    height:80vh;
    background-color:#dad2d2;
    box-sizing: border-box;
    font-family: calibri;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0px ;
  }
  #about .aboutTitle{
    color: #ffffff;
    padding-top: 40px;
  }
  #about .profile-p{
    justify-content: left;
    color:rgba(10, 10, 10, 0.911);
  }
  #contact{
    margin-top: 20px;
    width:100%;
    height:80vh;
    background-color:#dad2d2;
    box-sizing: border-box;
    font-family: calibri;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 50px ;
  }
  .s-heading h1{
    color:#FFFFFF;
    font-size: 3rem;
    margin: 0px;
    padding: 0px;
  
  }
  .s-heading p{
    color:rgba(247, 247, 247, 0.678);
    font-size: 1rem;
    margin: 0px;
    padding: 0px;
  }
  .s-heading{
    text-align: center;
    margin: 10px 0px;
  }
  .s-box{
    background-color: #ffffff;
    width:350px;
    height: 375px;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 2px 2px 12px rgba(0,0,0,0.1);
    position: relative;
  }

  .s-box3{
    background-color: #ffffff;
    width:500px;
    height: 470px;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 2px 2px 12px rgba(0,0,0,0.1);
    position: relative;
  }
  .box3-header{
    width: 100%;
    height: 40%;
    background-color:#ffffff;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
  .b-container{
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .s-b-img{
    width: 100%;
    height: 50%;
  
  }
  .s-b-img img{
    width:100%;
    height: 100%;
  
  }
  .s-b-text{
    width: 100%;
    height: 40%;
    background-color:#ffffff;
    display:flex;
    justify-content:center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
  .s-b-text p{
    margin: 0px;
    color:#000000;
    font-size: 1.1rem;
    font-family: calibri;
    display: block;
    display: -webkit-box;
    max-width: 80%;
    margin-top: 0px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .s-b-text p:hover{
    color:#000266;
  }
  .s-b-text a{
    margin-top:15px ;
  }
  .s-type{
    font-family: calibri;
    color:#000266;
    background-color: #ffffff;
    padding: 10px 15px;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 2px 2px 12px rgba(0,0,0,0.2);
  }
  .s-box:hover{
    transform:translateY(-10px);
    transition: all ease 0.3s;
  
  }

  .box-header{
    color:#9b047a;
  }
  .box3-header{
    color:#9b047a;
    align-items: center;
  }

  /*Contact media*/
 .contactIcons{
    display:flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    vertical-align: top;
  }
  .whatsApp{
    color: rgb(20, 85, 34);
    font-size: 50px;
  }
  .phone{
    color: rgb(70, 9, 49);
    text-shadow: 1px 1px 1px rgb(5, 71, 38);
    font-size: 50px;
  }
  .envelope{
    color: rgb(68, 7, 233);
    text-shadow: 1px 1px 1px rgb(5, 71, 38);
    font-size: 45px;
  }
  .download{
    color: rgb(68, 7, 233);
  }
  .btnAppFormDownload{
    color: #9b047a;
    background-color: #ffffff;
    margin-right: 5px;
  }
  .btnTSheetDownload{
    color: #9b047a;
    background-color: #ffffff;

  }
  .btnTimesheet{
    padding-top: 6px;
  }
  .s-box .s-b-text{
    height: fit-content;
  }
  .contactIcons{
    margin-top: 40px;
  }
  .menu li{
    width:100%;
    color: #fff;
}
.whatsAppF{
  color: rgb(20, 85, 34);
  text-shadow: 1px 1px 1px rgb(5, 71, 38);
  display: inline-flex;
  margin: 10px;
  font-size: 40px;
  line-height: 1.5;
}
.phoneF{
  color: rgb(250, 241, 247);
  text-shadow: 1px 1px 1px rgb(5, 71, 38);
  display: inline-flex;
  margin: 10px;
  font-size: 40px;
  line-height: 1.5;
}
.envelopeF{
  color: rgb(68, 7, 233);
  text-shadow: 1px 1px 1px rgb(5, 71, 38);
  display: inline-flex;
  margin: 10px;
  font-size: 40px;
  line-height: 1.5;
}
.contactIconsOnFooter{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #fff;
  text-align: center;
}

.g-map{
  width: 100%;
}

.footer{
  background-color: rgb(88, 4, 60);
  width: 100%;
  height: 200px;
}

  @media(max-width:1190px){
  #main{
    background-size: 100% !important;
    width: auto;
  }
  .name{
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .header-btns{
    margin-left: 10em;
  }
  
  .features-model img{
    height:400px;
    }
    #services{
    height:auto;
    }
    #about{
      height: auto;
    }
    #contact{
      height: auto;
    }
    .b-container{
    flex-wrap: wrap;
  }
    .s-box{
    flex-grow: 1;
  }
  .s-box1{
    flex-grow: 1;
  }
  .s-b-img img{
    object-fit:cover;
  }
  .s-box .s-b-text{
    height: fit-content;
  }
  
  }
  .logodiv {
    display: none;
  }
  
  @media(max-width:970px){
    #main{
      background-size: 970px !important;
      margin-left: -115px;
      width: auto;
    }
    #services{
      height: auto;
    }
  .features-model{
    display: none;
  }
  .s-heading h1{
    font-size: 2.5rem;
    color: #9b047a;
  }
  .features-text h2{
    font-weight: 600;
  }
  .b-container{
    margin-top: 30px;
  }
  .s-box .s-b-text{
    height: fit-content;
  }
  .footer{
    margin-top: 1rem;
    padding: 1rem;
    background-color: rgb(88, 4, 60);
    bottom: 0;
    left: 0;
    width: 100%;
    height: 260px;
  }
  .contactIconsOnFooter{
    margin-top: 0px;
    display:flex;
    justify-content:center;
    align-items: center;
    color: #fff;
    text-align: center;
    flex-direction:column;
  }
  .whatsAppF{
    color: rgb(20, 85, 34);
    text-shadow: 1px 1px 1px rgb(5, 71, 38);
    font-size: 30px;
  }
  .phoneF{
    color: rgb(250, 241, 247);
    text-shadow: 1px 1px 1px rgb(5, 71, 38);
    font-size: 30px;
  }
  .envelopeF{
    color: rgb(68, 7, 233);
    text-shadow: 1px 1px 1px rgb(5, 71, 38);
    font-size: 30px;
  }
  .logodiv {
    display: block;
    padding-bottom: 0%;
  }
  }
  
  @media screen and (min-width:100px) and (max-width:780px) {
    #main{
      background-size: 100% !important;
      width: auto;
    }
    #main h1{
      color: #9b047a;      
    }
    #main h1 span{
      font-size: 30px;
    }
  .name{
    width:60%;
    top: 450px;
    }
    .active,
    .services,
    .about,
    .contact{
      
      text-align: center;
      padding: 32px;
      width: 100%;
      transition: all 0.5s ease;
    }
    .logoH {
      display: block;
      width: 200px;
      height: 200px;
    }
    .details-contact-us{
      font-size:medium;
    }
}

/* ipad Mini Landscape */
@media screen and (min-width:768px) and (max-width:1702px) {
  #main{
    background-size:100% !important;
  }
  .logodiv {
    display: none;
    padding-bottom: 0%;
  }
  #main h1{
    color: #9b047a;
    
  }
  #main h1 span{
    font-size: 30px;
    
  }
.name{
  width:60%;
  top: 580px;
  }
  .nav-cont .active{
    color: #fff;
      text-align: center;
      
      width: 100%;
      transition: all 0.5s ease;
  }
  .nav-cont .about{
      text-align: center;
      width: 100%;
      transition: all 0.5s ease;
    }
    .nav-cont .services{
      color: #fff;
      text-align: center;
      width: 100%;
      transition: all 0.5s ease;
    }
    .nav-cont .contact{
      color: #fff;
      text-align: center;
      width: 100%;
      transition: all 0.5s ease;
    }

}
    .scroll{
        left: 42.5%;
    }
        @media(max-width:400px){
            .s-box{
                width: 100%;
                height:450px;
            }
            .s-box3{
              width: 100%;
              height:450px;
          }
          .s-box .s-b-text{
            height: fit-content;
          }
          
        }

@media only screen and (-webkit-min-device-pixel-ratio: 2.5) {
          /* CSS for Microsoft surface Duo*/
          #main{
            background-size:100% !important;
          }
        }